import React from 'react';

const CursorHelper = ({ enabled, position, scale, snappedPosition, previewPointScale }) => {
    if (!enabled) return null;

    const size = 20 * scale;
    const displayPosition = snappedPosition || position;

    return (
        <>
            <svg
                width={size}
                height={size}
                style={{
                    position: 'absolute',
                    left: displayPosition.x - size / 2,
                    top: displayPosition.y - size / 2,
                    pointerEvents: 'none',
                }}
            >
                <circle
                    cx={size / 2}
                    cy={size / 2}
                    r={size / 2 - 1}
                    stroke="white"
                    strokeWidth="2"
                    fill="none"
                />
                <line
                    x1={size / 2}
                    y1="0"
                    x2={size / 2}
                    y2={size}
                    stroke="white"
                    strokeWidth="2"
                />
                <line
                    x1="0"
                    y1={size / 2}
                    x2={size}
                    y2={size / 2}
                    stroke="white"
                    strokeWidth="2"
                />
            </svg>
            <div
                style={{
                    position: 'absolute',
                    left: displayPosition.x + size / 2 + 5,
                    top: displayPosition.y + size / 2 + 5,
                    color: 'white',
                    fontSize: '12px',
                    pointerEvents: 'none',
                }}
            >
                Cursor Scale: {scale.toFixed(2)}
                <br />
                Preview Point Scale: {previewPointScale.toFixed(2)}
                <br />
                X: {Math.round(displayPosition.x)}, Y: {Math.round(displayPosition.y)}
            </div>
        </>
    );
};

export default CursorHelper;