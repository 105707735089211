import React from 'react';
import DocumentationLayout from './components/DocumentationLayout';

function App() {
  return (
    <div className="App w-screen h-screen overflow-hidden">
      <DocumentationLayout />
    </div>
  );
}

export default App;