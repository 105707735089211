import React, { useCallback, useEffect, useRef, useState } from 'react';
import ControlPlot from './ControlPlot';
import Exporter from './Exporter';


const MultiBoxCurve = () => {
    const [rects, setRects] = useState([]);
    const [isDrawingRect, setIsDrawingRect] = useState(false);
    const [isMovingRect, setIsMovingRect] = useState(false);
    const [isDrawingCurve, setIsDrawingCurve] = useState(false);
    const [startPoint, setStartPoint] = useState({ x: 0, y: 0 });
    const [currentRect, setCurrentRect] = useState(null);
    const [activeRect, setActiveRect] = useState(null);
    const [hoverRect, setHoverRect] = useState(null);
    const [interpolationPoints, setInterpolationPoints] = useState(8);
    const canvasRef = useRef(null);
    

    const drawAll = useCallback(() => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        // Draw rectangles and their curves on main canvas
        rects.forEach((rect, index) => {
            drawRect(ctx, rect, index);
            if (rect.linkedCurve && rect.linkedCurve.length > 0) {
                drawCurve(ctx, rect.linkedCurve, rect);
            }
        });

        // Draw current rectangle if any
        if (currentRect) {
            ctx.save();
            ctx.shadowColor = 'rgba(0, 0, 0, 0.5)';
            ctx.shadowBlur = 10;
            ctx.shadowOffsetX = 5;
            ctx.shadowOffsetY = 5;
            ctx.strokeStyle = 'red';
            ctx.lineWidth = 2;
            ctx.strokeRect(currentRect.x, currentRect.y, currentRect.width, currentRect.height);
            ctx.restore();
        }
    }, [rects, currentRect, activeRect, hoverRect, interpolationPoints]);

    useEffect(() => {
        drawAll();
    }, [drawAll]);

    const drawRect = (ctx, rect, index) => {
        ctx.save();
        if (index === activeRect) {
            ctx.strokeStyle = 'green';
        } else if (index === hoverRect) {
            ctx.strokeStyle = 'orange';
        } else {
            ctx.strokeStyle = 'white';
        }
        ctx.lineWidth = 2;
        ctx.strokeRect(rect.x, rect.y, rect.width, rect.height);
        ctx.restore();
    };

    const drawCurve = (ctx, points, rect) => {
        if (points.length < 2) return;

        const controlPoints = interpolatePoints(points, interpolationPoints);

        ctx.save();
        ctx.beginPath();
        ctx.rect(rect.x, rect.y, rect.width, rect.height);
        ctx.clip();

        ctx.beginPath();
        ctx.moveTo(rect.x + controlPoints[0].x * rect.width, rect.y + controlPoints[0].y * rect.height);

        for (let i = 1; i < controlPoints.length; i++) {
            ctx.lineTo(rect.x + controlPoints[i].x * rect.width, rect.y + controlPoints[i].y * rect.height);
        }

        ctx.strokeStyle = 'green';
        ctx.lineWidth = 2;
        ctx.stroke();

        // Draw control points
        ctx.fillStyle = 'yellow';
        controlPoints.forEach(point => {
            ctx.fillRect(rect.x + point.x * rect.width - 3, rect.y + point.y * rect.height - 3, 6, 6);
        });

        ctx.restore();
    };

    const handleAxisChange = (index, axisType, value) => {
        setRects(rects.map((rect, i) => {
            if (i === index) {
                return {
                    ...rect,
                    [axisType]: value
                };
            }
            return rect;
        }));
    };



    const interpolatePoints = (points, count) => {
        if (points.length < 2) return points;

        // Calculate the total length of the curve
        let totalLength = 0;
        const segmentLengths = [];
        for (let i = 1; i < points.length; i++) {
            const dx = points[i].x - points[i - 1].x;
            const dy = points[i].y - points[i - 1].y;
            const segmentLength = Math.sqrt(dx * dx + dy * dy);
            segmentLengths.push(segmentLength);
            totalLength += segmentLength;
        }

        // Calculate the desired distance between interpolated points
        const desiredDistance = totalLength / (count - 1);

        const interpolatedPoints = [points[0]];
        let currentDistance = 0;
        let segmentIndex = 0;
        let segmentPosition = 0;

        for (let i = 1; i < count - 1; i++) {
            currentDistance += desiredDistance;

            // Find the appropriate segment
            while (currentDistance > segmentLengths[segmentIndex] && segmentIndex < segmentLengths.length - 1) {
                currentDistance -= segmentLengths[segmentIndex];
                segmentIndex++;
                segmentPosition = 0;
            }

            // Interpolate within the segment
            segmentPosition = currentDistance / segmentLengths[segmentIndex];
            const p1 = points[segmentIndex];
            const p2 = points[segmentIndex + 1];

            const newPoint = {
                x: p1.x + (p2.x - p1.x) * segmentPosition,
                y: p1.y + (p2.y - p1.y) * segmentPosition
            };

            interpolatedPoints.push(newPoint);
        }

        interpolatedPoints.push(points[points.length - 1]);

        return interpolatedPoints;
    };

    const handleMouseDown = (e) => {
        const canvas = canvasRef.current;
        const rect = canvas.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const y = e.clientY - rect.top;

        if (e.shiftKey) {
            setIsDrawingRect(true);
            setStartPoint({ x, y });
        } else if (e.ctrlKey) {
            const clickedRectIndex = rects.findIndex(r =>
                x >= r.x && x <= r.x + r.width &&
                y >= r.y && y <= r.y + r.height
            );
            if (clickedRectIndex !== -1) {
                setIsMovingRect(true);
                setActiveRect(clickedRectIndex);
                setStartPoint({ x, y });
            }
        } else {
            const clickedRectIndex = rects.findIndex(r =>
                x >= r.x && x <= r.x + r.width &&
                y >= r.y && y <= r.y + r.height
            );
            if (clickedRectIndex !== -1) {
                setActiveRect(clickedRectIndex);
                setIsDrawingCurve(true);
                const newPoint = {
                    x: (x - rects[clickedRectIndex].x) / rects[clickedRectIndex].width,
                    y: (y - rects[clickedRectIndex].y) / rects[clickedRectIndex].height
                };
                handleCurveChange(clickedRectIndex, true, [newPoint]);
            }
        }
    };

    const handleMouseMove = (e) => {
        const canvas = canvasRef.current;
        const rect = canvas.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const y = e.clientY - rect.top;

        if (isDrawingRect) {
            const width = x - startPoint.x;
            const height = y - startPoint.y;

            // Determine the size of the square based on the larger dimension
            const size = Math.max(Math.abs(width), Math.abs(height));

            // Adjust the x and y coordinates to maintain the starting corner
            const newX = width >= 0 ? startPoint.x : startPoint.x - size;
            const newY = height >= 0 ? startPoint.y : startPoint.y - size;

            setCurrentRect({ x: newX, y: newY, width: size, height: size });
        } else if (isMovingRect && activeRect !== null) {
            const dx = x - startPoint.x;
            const dy = y - startPoint.y;
            setRects(rects.map((r, index) => {
                if (index === activeRect) {
                    return {
                        ...r,
                        x: r.x + dx,
                        y: r.y + dy,
                        linkedCurve: r.linkedCurve.map(point => ({
                            x: point.x,
                            y: point.y
                        }))
                    };
                }
                return r;
            }));
            setStartPoint({ x, y });
        } else if (isDrawingCurve && activeRect !== null) {
            const activeRectData = rects[activeRect];
            if (x >= activeRectData.x && x <= activeRectData.x + activeRectData.width &&
                y >= activeRectData.y && y <= activeRectData.y + activeRectData.height) {
                const newPoint = {
                    x: (x - activeRectData.x) / activeRectData.width,
                    y: (y - activeRectData.y) / activeRectData.height
                };
                handleCurveChange(activeRect, true, (prevCurve) => [...prevCurve, newPoint]);
            }
        } else {
            const hoverRectIndex = rects.findIndex(r =>
                x >= r.x && x <= r.x + r.width &&
                y >= r.y && y <= r.y + r.height
            );
            setHoverRect(hoverRectIndex !== -1 ? hoverRectIndex : null);
        }
    };

    const handleMouseUp = () => {
        if (isDrawingRect && currentRect) {
            const newRect = {
                x: Math.min(startPoint.x, currentRect.x + currentRect.width),
                y: Math.min(startPoint.y, currentRect.y + currentRect.height),
                width: Math.abs(currentRect.width),
                height: Math.abs(currentRect.height),
                linkedCurve: [],
                unlinkedCurve: [],
                renderMode: 'R',
                overrideIndex: null,
                forwardAxis: 'Z',  // Set default forward axis
                upAxis: 'X'         // Set default up axis
            };
            setRects([...rects, newRect]);
            setCurrentRect(null);
        }
        setIsDrawingRect(false);
        setIsMovingRect(false);
        setIsDrawingCurve(false);
        setActiveRect(null);
    };

    const handleCurveChange = (index, isLinked, newCurve) => {
        setRects(rects.map((rect, i) => {
            if (i === index) {
                return {
                    ...rect,
                    [isLinked ? 'linkedCurve' : 'unlinkedCurve']: typeof newCurve === 'function' ? newCurve(rect[isLinked ? 'linkedCurve' : 'unlinkedCurve']) : newCurve
                };
            }
            return rect;
        }));
    };

    const handleRenderModeToggle = (index, newMode) => {
        setRects(rects.map((rect, i) => {
            if (i === index) {
                if (newMode === 'R' && rect.renderMode === 'O') {
                    // Switching from 'O' to 'R', restore the original curve
                    return {
                        ...rect,
                        renderMode: newMode,
                        unlinkedCurve: rect.originalUnlinkedCurve || rect.unlinkedCurve,
                        overrideIndex: null
                    };
                } else if (newMode === 'O' && rect.renderMode === 'R') {
                    // Switching from 'R' to 'O', store the original curve
                    return {
                        ...rect,
                        renderMode: newMode,
                        originalUnlinkedCurve: rect.unlinkedCurve,
                        overrideIndex: null
                    };
                }
                // If it's already in the new mode, just return the rect as is
                return { ...rect, renderMode: newMode };
            }
            return rect;
        }));
    };

    const handleOverrideChange = (index, overrideIndex) => {
        setRects(rects.map((rect, i) => {
            if (i === index) {
                return {
                    ...rect,
                    unlinkedCurve: rects[overrideIndex]?.unlinkedCurve || [],
                    overrideIndex: overrideIndex
                };
            }
            return rect;
        }));
    };

    return (
        <div className="flex font-mono text-white font-bold">
            <div className="w-full max-w-lg">
                <canvas
                    ref={canvasRef}
                    width={512}
                    height={512}
                    onMouseDown={handleMouseDown}
                    onMouseMove={handleMouseMove}
                    onMouseUp={handleMouseUp}
                    onMouseLeave={handleMouseUp}
                    className="border border-gray-300"
                />
                <div className="mt-4">
                    <label htmlFor="interpolation-points" className="block text-sm font-medium">
                        Interpolation Points:
                    </label>
                    <input
                        type="number"
                        id="interpolation-points"
                        value={interpolationPoints}
                        onChange={(e) => setInterpolationPoints(parseInt(e.target.value, 10))}
                        min="2"
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 text-black"
                    />
                </div>
                <p className="mt-4 text-sm">
                    Shift+Click and drag to create rectangles. Ctrl+Click and drag to move rectangles. Click and drag inside rectangles to draw curves.
                </p>
                <Exporter rects={rects} canvasWidth={512} canvasHeight={512} />
            </div>
            <div className="ml-4 h-[calc(100vh-2rem)] overflow-y-auto">
                <div className="grid grid-cols-2 gap-4 content-start">
                    {[...Array(Math.max(2, rects.length))].map((_, index) => (
                        <React.Fragment key={index}>
                            <div className="w-32 border border-gray-300">
                                <div className="bg-gray-800 p-1">
                                    <h3 className="text-center text-xs">
                                        Plot {index + 1}
                                    </h3>
                                </div>
                                {index < rects.length ? (
                                    <ControlPlot
                                        width={128}
                                        height={128}
                                        curve={rects[index].linkedCurve}
                                        isLinked={true}
                                        onCurveChange={(newCurve) => handleCurveChange(index, true, newCurve)}
                                        renderMode={rects[index].renderMode}
                                        onRenderModeToggle={(newMode) => handleRenderModeToggle(index, newMode)}
                                        rectIndex={index}
                                        totalRects={rects.length}
                                        forwardAxis={rects[index].forwardAxis}
                                        upAxis={rects[index].upAxis}
                                        onAxisChange={(axisType, value) => handleAxisChange(index, axisType, value)}
                                    />
                                ) : (
                                    <div className="h-32 flex items-center justify-center text-gray-400">
                                        No rect added
                                    </div>
                                )}
                            </div>
                            <div className="w-32 border border-gray-300">
                                <div className="bg-gray-800 p-1">
                                    <h3 className="text-center text-xs">
                                        Plot {index + 1}
                                    </h3>
                                </div>
                                {index < rects.length ? (
                                    <ControlPlot
                                        width={128}
                                        height={128}
                                        curve={rects[index].unlinkedCurve}
                                        isLinked={false}
                                        onCurveChange={(newCurve) => handleCurveChange(index, false, newCurve)}
                                        renderMode={rects[index].renderMode}
                                        onRenderModeToggle={(newMode) => handleRenderModeToggle(index, newMode)}
                                        rectIndex={index}
                                        totalRects={rects.length}
                                        onOverrideChange={(overrideIndex) => handleOverrideChange(index, overrideIndex)}
                                        overrideIndex={rects[index].overrideIndex}
                                    />
                                ) : (
                                    <div className="h-32 flex items-center justify-center text-gray-400">
                                        No rect added
                                    </div>
                                )}
                            </div>
                        </React.Fragment>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default MultiBoxCurve;