import React from 'react';

const RenderModeToggle = ({ renderMode, onToggle }) => {
    return (
        <div className="flex border border-gray-300 rounded-md overflow-hidden">
            <button
                className={`w-1/2 py-2 px-4 ${renderMode === 'R' ? 'bg-yellow-500 text-white' : 'bg-white text-gray-700'}`}
                onClick={() => onToggle('R')}
            >
                R
            </button>
            <button
                className={`w-1/2 py-2 px-4 ${renderMode === 'O' ? 'bg-yellow-500 text-white' : 'bg-white text-gray-700'}`}
                onClick={() => onToggle('O')}
            >
                O
            </button>
        </div>
    );
};

export default RenderModeToggle;