import React from 'react';

const Crosshair = ({ x, y }) => {
    return (
        <>
            <div
                className="crosshair-vertical"
                style={{ left: `${x}px` }}
            ></div>
            <div
                className="crosshair-horizontal"
                style={{ top: `${y}px` }}
            ></div>
        </>
    );
};

export default Crosshair;