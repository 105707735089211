import React from 'react';

const GridSnapper = ({ gridSize, enabled }) => {
    if (!enabled) return null;

    const gridLines = [];
    const cellSize = 512 / gridSize;

    for (let i = 1; i < gridSize; i++) {
        const position = i * cellSize;
        gridLines.push(
            <line
                key={`vertical-${i}`}
                x1={position}
                y1={0}
                x2={position}
                y2={512}
                stroke="rgba(255, 255, 255, 0.2)"
                strokeWidth="1"
            />,
            <line
                key={`horizontal-${i}`}
                x1={0}
                y1={position}
                x2={512}
                y2={position}
                stroke="rgba(255, 255, 255, 0.2)"
                strokeWidth="1"
            />
        );
    }

    return (
        <svg
            width="512"
            height="512"
            style={{
                position: 'absolute',
                top: 0,
                left: 0,
                pointerEvents: 'none',
            }}
        >
            {gridLines}
        </svg>
    );
};

export default GridSnapper;