import React from 'react';

const HomePage = () => {
    return (
        <div className="min-h-screen bg-black text-white font-mono p-4">
            <div className="max-w-xl mx-auto border border-white p-6">
                <header className="mb-6 flex items-center justify-center flex-col">
                    <img src="/logo.jpg" alt="AMMPTT Logo" className="w-24 h-24 mb-3" /> {/* Reduced logo size */}
                    <h1 className="text-2xl font-mono-bold mb-1">AMMPTT</h1>
                    <p className="text-sm">Empowering Creators Through Technology</p>
                </header>

                <main className="text-sm">
                    <section className="mb-4">
                        <h2 className="text-lg font-bold mb-2">About the Founder</h2>
                        <p>
                            Jacob is the visionary creator and founder of AMMPTT, a software
                            development company dedicated to empowering creators through innovative tools
                            and educational content.
                        </p>
                    </section>

                    <section className="mb-4">
                        <h2 className="text-lg font-bold mb-2">Our Mission</h2>
                        <p className="mb-2">
                            At AMMPTT, we focus on developing a diverse range of proof-of-concept tools and applications,
                            including but not limited to:
                        </p>
                        <ul className="list-disc list-inside">
                            <li>Game development utilities</li>
                            <li>3D modeling applications</li>
                            <li>Fullstack engineering</li>
                            <li>Educational resources for creators</li>
                        </ul>
                    </section>

                    <section>
                        <h2 className="text-lg font-bold mb-2">Get In Touch</h2>
                        <p>
                            Interested in our projects or want to collaborate?
                            <a href="mailto:ceo@collecto.dev" className="underline ml-1">Contact us</a>
                        </p>
                    </section>
                </main>

                <footer className="mt-6 pt-4 border-t border-white text-center text-xs">
                    <p>&copy; 2024 AMMPTT. All rights reserved.</p>
                </footer>
            </div>
        </div>
    );
};

export default HomePage;