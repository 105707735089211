import React, { useCallback, useEffect, useRef, useState } from 'react';
import Crosshair from './Crosshair';
import CursorHelper from './CursorHelper';
import GridSnapper from './GridSnapper';
import RectSelector from './RectSelector';

const ControlPlotInput = ({
    children,
    gridSize,
    gridEnabled,
    cursorEnabled,
    onCursorScaleChange,
    lists,
    onPointAdd,
    startingScale,
    incrementValue,
    incrementRatePerSecond,
    scaleBleedEnabled,
    renderOrder
}) => {
    const [mousePos, setMousePos] = useState({ x: 0, y: 0 });
    const [snappedPos, setSnappedPos] = useState({ x: 0, y: 0 });
    const [isSelecting, setIsSelecting] = useState(false);
    const [selectionStart, setSelectionStart] = useState(null);
    const [selectionEnd, setSelectionEnd] = useState(null);
    const [cursorScale, setCursorScale] = useState(1);
    const [isShiftPressed, setIsShiftPressed] = useState(false);
    const [isMouseDown, setIsMouseDown] = useState(false);
    const [previewPointScale, setPreviewPointScale] = useState(startingScale);
    const mouseDownPosRef = useRef(null);
    const mouseDownTimeRef = useRef(null);
    const hasMoveRef = useRef(false);
    const hasPassedThresholdRef = useRef(false);


    const MOVEMENT_THRESHOLD = 25;

    const snapToGrid = useCallback((pos) => {
        if (!gridEnabled) return pos;
        const cellSize = 512 / gridSize;
        return {
            x: Math.round(pos.x / cellSize) * cellSize,
            y: Math.round(pos.y / cellSize) * cellSize
        };
    }, [gridEnabled, gridSize]);

    const handleMouseMove = useCallback((event) => {
        const rect = event.currentTarget.getBoundingClientRect();
        const newPos = {
            x: event.clientX - rect.left,
            y: event.clientY - rect.top
        };
        setMousePos(newPos);
        setSnappedPos(snapToGrid(newPos));

        if (isMouseDown && mouseDownPosRef.current) {
            const dx = newPos.x - mouseDownPosRef.current.x;
            const dy = newPos.y - mouseDownPosRef.current.y;
            const distance = Math.sqrt(dx * dx + dy * dy);

            if (!hasPassedThresholdRef.current && distance > MOVEMENT_THRESHOLD) {
                hasPassedThresholdRef.current = true;
                hasMoveRef.current = true;
                setIsSelecting(true);
            }

            if (hasPassedThresholdRef.current) {
                setSelectionEnd(newPos);
            }
        }
    }, [isMouseDown, snapToGrid]);

    const handleMouseDown = useCallback((event) => {
        if (event.button === 0 && !isShiftPressed) {
            const rect = event.currentTarget.getBoundingClientRect();
            const clickPos = {
                x: event.clientX - rect.left,
                y: event.clientY - rect.top
            };
            mouseDownPosRef.current = clickPos;
            mouseDownTimeRef.current = Date.now();
            setIsMouseDown(true);
            setSelectionStart(clickPos);
            setSelectionEnd(clickPos);
            setPreviewPointScale(startingScale);
            hasMoveRef.current = false;
            hasPassedThresholdRef.current = false;
        }
    }, [isShiftPressed, startingScale]);

    const handleMouseUp = useCallback((event) => {
        if (event.button === 0 && !isShiftPressed) {
            const rect = event.currentTarget.getBoundingClientRect();
            const clickPos = {
                x: event.clientX - rect.left,
                y: event.clientY - rect.top
            };
            const snappedClickPos = snapToGrid(clickPos);

            if (mouseDownPosRef.current && !hasMoveRef.current) {
                if (lists.length > 0) {
                    onPointAdd({ ...snappedClickPos, scale: previewPointScale });
                }
            }
        }

        setIsSelecting(false);
        setSelectionStart(null);
        setSelectionEnd(null);
        mouseDownPosRef.current = null;
        mouseDownTimeRef.current = null;
        setIsMouseDown(false);
        setPreviewPointScale(startingScale);
        hasMoveRef.current = false;
        hasPassedThresholdRef.current = false;
    }, [isShiftPressed, lists, onPointAdd, snapToGrid, previewPointScale, startingScale]);

    const handleWheel = useCallback((event) => {
        if (isShiftPressed && cursorEnabled) {
            event.preventDefault();
            const newScale = Math.max(0.1, cursorScale - event.deltaY * 0.001);
            setCursorScale(newScale);
            onCursorScaleChange(newScale);
        }
    }, [isShiftPressed, cursorEnabled, cursorScale, onCursorScaleChange]);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Shift') setIsShiftPressed(true);
        };

        const handleKeyUp = (event) => {
            if (event.key === 'Shift') setIsShiftPressed(false);
        };

        window.addEventListener('keydown', handleKeyDown);
        window.addEventListener('keyup', handleKeyUp);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
            window.removeEventListener('keyup', handleKeyUp);
        };
    }, []);

    useEffect(() => {
        let animationFrameId;

        const updateScale = () => {
            if (isMouseDown && scaleBleedEnabled && !hasMoveRef.current) {
                const currentTime = Date.now();
                const elapsedTime = (currentTime - mouseDownTimeRef.current) / 1000;
                const scaleDelta = incrementValue * incrementRatePerSecond * elapsedTime;
                const newPreviewScale = startingScale + scaleDelta;
                setPreviewPointScale(newPreviewScale);
            }

            animationFrameId = requestAnimationFrame(updateScale);
        };

        updateScale();

        return () => {
            cancelAnimationFrame(animationFrameId);
        };
    }, [isMouseDown, scaleBleedEnabled, incrementValue, incrementRatePerSecond, startingScale]);

    const renderLines = useCallback(() => {
        return lists.map((list, listIndex) => {
            if (!list.visible || list.points.length < 2) return null;

            const pathPoints = list.points.map(point => `${point.x},${point.y}`).join(' ');

            return (
                <svg key={`line-${listIndex}`} style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', pointerEvents: 'none' }}>
                    <polyline
                        points={pathPoints}
                        fill="none"
                        stroke={list.color}
                        strokeWidth="2"
                    />
                </svg>
            );
        });
    }, [lists]);

    const renderPreviewPoint = useCallback(() => {
        if (!isMouseDown || hasMoveRef.current) return null;

        const size = 6 * previewPointScale;
        return (
            <div
                style={{
                    position: 'absolute',
                    left: snappedPos.x,
                    top: snappedPos.y,
                    width: `${size}px`,
                    height: `${size}px`,
                    backgroundColor: 'rgba(255, 255, 255, 0.65)',
                    borderRadius: '50%',
                    transform: 'translate(-50%, -50%)',
                    pointerEvents: 'none',
                    zIndex: renderOrder === 'front' ? 3000 : 5000,
                }}
            />
        );
    }, [isMouseDown, previewPointScale, snappedPos, renderOrder]);

    const renderPoint = (point, listColor, renderType) => {
        const size = 6 * (point.scale || 1);
        const halfSize = size / 2;

        const commonStyle = {
            position: 'absolute',
            left: point.x,
            top: point.y,
            width: `${size}px`,
            height: `${size}px`,
            transform: 'translate(-50%, -50%)',
            zIndex: 1,
        };

        const renderSVG = (svgContent) => (
            <div style={commonStyle}>
                <svg width={size} height={size} viewBox="0 0 100 100">
                    {svgContent}
                </svg>
            </div>
        );

        switch (renderType) {
            case 'circle':
                return renderSVG(
                    <circle cx="50" cy="50" r="50" fill={listColor} />
                );
            case 'triangle':
                return renderSVG(
                    <polygon points="50,13.4 100,100 0,100" fill={listColor} />
                );
            case 'rect':
                return renderSVG(
                    <rect x="0" y="0" width="100" height="100" fill={listColor} />
                );
            case 'horizontal':
                return renderSVG(
                    <rect x="0" y="40" width="100" height="20" fill={listColor} />
                );
            case 'vertical':
                return renderSVG(
                    <rect x="40" y="0" width="20" height="100" fill={listColor} />
                );
            case 'diagLeft':
                return renderSVG(
                    <line x1="0" y1="0" x2="100" y2="100" stroke={listColor} strokeWidth="20" strokeLinecap="round" />
                );
            case 'diagRight':
                return renderSVG(
                    <line x1="100" y1="0" x2="0" y2="100" stroke={listColor} strokeWidth="20" strokeLinecap="round" />
                );
            default:
                return renderSVG(
                    <circle cx="50" cy="50" r="50" fill={listColor} />
                );
        }
    };

    return (
        <div
            className="control-plot-input"
            onMouseMove={handleMouseMove}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
            onWheel={handleWheel}
            style={{ position: 'relative', width: '100%', height: '100%' }}
        >
            {children}
            <Crosshair x={mousePos.x} y={mousePos.y} />
            <RectSelector startPoint={selectionStart} endPoint={selectionEnd} />
            <GridSnapper gridSize={gridSize} enabled={gridEnabled} />
            <CursorHelper
                enabled={cursorEnabled && !hasMoveRef.current}
                position={mousePos}
                snappedPosition={gridEnabled ? snappedPos : null}
                scale={cursorScale}
                previewPointScale={previewPointScale}
            />
            {renderLines()}
            {renderPreviewPoint()}
            {lists.map((list, listIndex) =>
                list.visible && list.points.map((point, pointIndex) => (
                    <React.Fragment key={`${listIndex}-${pointIndex}`}>
                        {renderPoint(point, list.color, list.renderType)}
                    </React.Fragment>
                ))
            )}
        </div>
    );
};

export default ControlPlotInput;