import React from 'react';

const RectSelector = ({ startPoint, endPoint }) => {
    if (!startPoint || !endPoint) return null;

    const left = Math.min(startPoint.x, endPoint.x);
    const top = Math.min(startPoint.y, endPoint.y);
    const width = Math.abs(endPoint.x - startPoint.x);
    const height = Math.abs(endPoint.y - startPoint.y);

    return (
        <div
            style={{
                position: 'absolute',
                left: `${left}px`,
                top: `${top}px`,
                width: `${width}px`,
                height: `${height}px`,
                border: '1px solid #00FF00',
                pointerEvents: 'none',
            }}
        />
    );
};

export default RectSelector;