import { MenuIcon, PanelLeftClose } from 'lucide-react';
import React, { useState } from 'react';
import About from './About';
import MultiBoxCurve from './CurveBox/MCPC/MultiBoxCurve';
import Gridplotter from './Gridplotter/Gridplotter';
import HomePage from './HomePage';
import WorldMap from './worldmap/WorldMap';

const DocumentationLayout = () => {
    const [selectedOption, setSelectedOption] = useState('HOME');
    const [isSidebarMinimized, setIsSidebarMinimized] = useState(false);

    const renderContent = () => {
        switch (selectedOption) {
            case 'HOME':
                return <HomePage />;
            case 'DOCUMENTATION':
                return (
                    <iframe
                        src="/site/index.html"
                        className="w-full h-full border-none"
                        title="Documentation"
                    />
                );
            case 'WORLD_MAP':
                return <WorldMap />;
            case 'CURVE_BOX':
                return <MultiBoxCurve />;
            case 'GRIDPLOTTER':
                return <Gridplotter />;
            case 'ABOUT':
                return <About />;
            default:
                return null;
        }
    };

    const toggleSidebar = () => {
        setIsSidebarMinimized(!isSidebarMinimized);
    };

    const renderButton = (option, label) => (
        <li>
            <button
                className={`w-full text-left p-2 font-mono text-xs font-bold border border-white
                    ${selectedOption === option
                        ? 'bg-neutral-600 text-white'
                        : 'bg-neutral-700 text-neutral-300 hover:bg-neutral-600 hover:text-white'}
                    transition-colors duration-150 ease-in-out ${isSidebarMinimized ? 'px-1' : ''}`}
                onClick={() => setSelectedOption(option)}
            >
                {isSidebarMinimized ? label[0] : label}
            </button>
        </li>
    );

    return (
        <div className="flex h-screen w-full">
            {/* Left sidebar */}
            <div className={`bg-neutral-800 flex flex-col transition-all duration-300 ease-in-out ${isSidebarMinimized ? 'w-12' : 'w-[120px]'}`}>
                {/* Toggle button */}
                <button
                    onClick={toggleSidebar}
                    className="p-2 bg-neutral-700 hover:bg-neutral-600 transition-colors duration-200 flex justify-center items-center border border-neutral-600"
                    aria-label={isSidebarMinimized ? "Expand sidebar" : "Minimize sidebar"}
                >
                    {isSidebarMinimized ? (
                        <MenuIcon size={20} className="text-white" />
                    ) : (
                        <PanelLeftClose size={20} className="text-white" />
                    )}
                </button>
                <ul className="space-y-1 p-1 mt-1">
                    {renderButton('HOME', 'HOME')}
                    {renderButton('DOCUMENTATION', 'DOCUMENTATION')}
                    {renderButton('WORLD_MAP', 'WORLD MAP')}
                    {renderButton('CURVE_BOX', 'CURVE BOX')}
                    {renderButton('GRIDPLOTTER', 'GRIDPLOTTER')}
                    {renderButton('ABOUT', 'ABOUT')}
                </ul>
            </div>

            {/* Main content area */}
            <div className="flex-grow bg-neutral-900 flex flex-col">
                <div className="flex-grow overflow-auto w-full">
                    {renderContent()}
                </div>
            </div>
        </div>
    );
};

export default DocumentationLayout;