import React from 'react';

const About = () => {
    return (
        <div className="min-h-screen bg-black text-white font-mono p-4">
            <div className="max-w-xl mx-auto border border-white p-6">
                <header className="mb-6 flex items-center justify-center flex-col">
                    <img src="/logo.jpg" alt="AMMPTT Logo" className="w-24 h-24 mb-3" />
                    <h1 className="text-2xl font-mono-bold mb-1">About:</h1>
                    <p className="text-sm">Empowering Creators Through Technology</p>
                </header>

                <main className="text-sm">
                    <section className="mb-4">
                        <h2 className="text-lg font-bold mb-2">Our Vision</h2>
                        <p>
                            At AMMPTT, we're dedicated to empowering creators through innovative tools and educational content. Our focus is on developing a wide range of proof-of-concept tools and applications that push the boundaries of what's possible in game development, 3D modeling, and fullstack engineering.
                        </p>
                    </section>

                    <section className="mb-4">
                        <h2 className="text-lg font-bold mb-2">Hobby Projects and Open Source</h2>
                        <p>
                            Outside of our core documentation, many of our projects are hobby endeavors. We're actively working on consolidating these projects into a single standalone tool, with a specific focus on curve-related functionalities for 2D to 3D workflows. Most of these projects are being prepared for open-source release, allowing the community to benefit from and contribute to our work.
                        </p>
                    </section>

                    <section className="mb-4">
                        <h2 className="text-lg font-bold mb-2">Our Approach: Embracing "Intentional Jank"</h2>
                        <p>
                            We believe in the power of iteration and continuous improvement. Our approach, which we playfully call "intentional jank," focuses on establishing the iterative and testing processes first. Rather than waiting in theory and silence, we prioritize getting our ideas out there, even if they're not perfect. This allows us to gather valuable feedback and refine our projects over time. While there may be obvious areas for improvement, we consider it a success as long as we've established the core silhouette of our vision, not only for the individual component-based projects but our presence as a whole and the role we aim to position ourselves in.
                        </p>
                    </section>

                    <section>
                        <h2 className="text-lg font-bold mb-2">Get Involved</h2>
                        <p>
                            Interested in our projects or want to contribute? We welcome collaboration and feedback from the community. Our "intentional jank" approach means there's always room for improvement and fresh ideas. Stay tuned for our upcoming open-source releases and feel free to reach out with any questions, suggestions, or contributions.
                        </p>
                    </section>
                </main>

                <footer className="mt-6 pt-4 border-t border-white text-center text-xs">
                    <p>&copy; 2024 AMMPTT. All rights reserved.</p>
                </footer>
            </div>
        </div>
    );
};

export default About;