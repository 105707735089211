import React from 'react';

const StyledButton = ({ children, onClick, type = 'button' }) => {
    const buttonStyle = {
        fontFamily: 'monospace',
        fontSize: '14px',
        fontWeight: 'bold',
        color: '#ffffff',
        backgroundColor: '#3a3a3a',
        border: '1px solid #555555',
        padding: '4px 8px',
        cursor: 'pointer',
        outline: 'none',
    };

    return (
        <button
            style={buttonStyle}
            onClick={onClick}
            type={type}
        >
            {children}
        </button>
    );
};

export default StyledButton;