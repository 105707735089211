import React from 'react';

const Exporter = ({ rects, canvasWidth, canvasHeight }) => {
    const interpolatePoints = (points, count) => {
        if (points.length <= count) return points;
        const result = [];
        for (let i = 0; i < count; i++) {
            const index = (i / (count - 1)) * (points.length - 1);
            const lowerIndex = Math.floor(index);
            const upperIndex = Math.ceil(index);
            const t = index - lowerIndex;
            const lowerPoint = points[lowerIndex];
            const upperPoint = points[upperIndex];
            result.push({
                x: lowerPoint.x * (1 - t) + upperPoint.x * t,
                y: lowerPoint.y * (1 - t) + upperPoint.y * t
            });
        }
        return result;
    };

    const exportData = () => {
        const exportedData = rects.map((rect, index) => {
            const aspectRatio = rect.width / rect.height;

            const scalePoint = (point) => ({
                x: point.x * aspectRatio,
                y: 1 - point.y, // Invert Y-axis
                z: 0 // Default to 0 for now
            });

            const interpolatedProfileCurve = interpolatePoints(rect.linkedCurve, 8);
            const interpolatedStagedCurve = interpolatePoints(
                rect.renderMode === 'O' && rect.overrideIndex !== null
                    ? rects[rect.overrideIndex].unlinkedCurve
                    : rect.unlinkedCurve,
                8
            );

            const profileCurve = interpolatedProfileCurve.map(scalePoint);
            const stagedCurve = interpolatedStagedCurve.map(scalePoint);

            return {
                index,
                position: {
                    x: rect.x / canvasWidth,
                    y: 1 - (rect.y / canvasHeight), // Invert Y-axis
                    z: 0 // Default to 0 for now
                },
                size: {
                    width: rect.width / canvasWidth,
                    height: rect.height / canvasHeight
                },
                profileCurve: {
                    points: profileCurve,
                    forwardAxis: rect.forwardAxis,
                    upAxis: rect.upAxis
                },
                stagedCurve: {
                    points: stagedCurve,
                    overrideIndex: rect.renderMode === 'O' ? rect.overrideIndex : null
                }
            };
        });

        const jsonData = JSON.stringify(exportedData, null, 2);
        const blob = new Blob([jsonData], { type: 'application/json' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'exported_data.json';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    };

    return (
        <div className="mt-4">
            <button
                onClick={exportData}
                className="w-32 text-center p-2 font-mono text-xs font-bold border border-white bg-neutral-700 text-neutral-300 hover:bg-neutral-600 hover:text-white transition-colors duration-150 ease-in-out"
            >
                EXPORT DATA
            </button>
        </div>
    );
};

export default Exporter;