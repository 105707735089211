import React, { useEffect, useRef, useState } from 'react';
import RenderModeToggle from './RenderModeToggle';



const ForwardAxisToggle = ({ label, value, onChange }) => {
    const axes = ['X', 'Y', 'Z', '-X', '-Y', '-Z'];
    return (
        <div className="flex flex-col items-center mt-1">
            <span className="text-xs">{label}</span>
            <div className="flex border border-gray-300 rounded-md overflow-hidden">
                {axes.map((axis) => (
                    <button
                        key={axis}
                        className={`px-1 py-0.5 text-xs ${value === axis ? 'bg-yellow-500 text-white' : 'bg-white text-gray-700'}`}
                        onClick={() => onChange(axis)}
                    >
                        {axis}
                    </button>
                ))}
            </div>
        </div>
    );
};

const UpAxisToggle = ({ label, value, onChange }) => {
    const axes = ['X', 'Y', 'Z'];
    return (
        <div className="flex flex-col items-center mt-1">
            <span className="text-xs">{label}</span>
            <div className="flex border border-gray-300 rounded-md overflow-hidden">
                {axes.map((axis) => (
                    <button
                        key={axis}
                        className={`px-1 py-0.5 text-xs ${value === axis ? 'bg-yellow-500 text-white' : 'bg-white text-gray-700'}`}
                        onClick={() => onChange(axis)}
                    >
                        {axis}
                    </button>
                ))}
            </div>
        </div>
    );
};

const ControlPlot = ({
    width,
    height,
    curve,
    isLinked,
    onCurveChange,
    renderMode,
    onRenderModeToggle,
    rectIndex,
    totalRects,
    onOverrideChange,
    forwardAxis,
    upAxis,
    onAxisChange
}) => {
    const canvasRef = useRef(null);
    const [overrideValue, setOverrideValue] = useState(0);
    const [isDrawing, setIsDrawing] = useState(false);

    useEffect(() => {
        drawCurve();
    }, [curve, width, height, renderMode]);

    const drawCurve = () => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        ctx.clearRect(0, 0, width, height);

        // Draw grid
        ctx.strokeStyle = 'rgba(200, 200, 200, 0.5)';
        ctx.lineWidth = 1;
        for (let i = 0; i <= width; i += 16) {
            ctx.beginPath();
            ctx.moveTo(i, 0);
            ctx.lineTo(i, height);
            ctx.stroke();
        }
        for (let i = 0; i <= height; i += 16) {
            ctx.beginPath();
            ctx.moveTo(0, i);
            ctx.lineTo(width, i);
            ctx.stroke();
        }

        // Draw curve
        if (curve && curve.length > 0) {
            ctx.beginPath();
            ctx.moveTo(curve[0].x * width, curve[0].y * height);
            for (let i = 1; i < curve.length; i++) {
                ctx.lineTo(curve[i].x * width, curve[i].y * height);
            }
            ctx.strokeStyle = 'blue';
            ctx.lineWidth = 2;
            ctx.stroke();
        }

        // Draw interpolation points
        const interpolated = interpolatePoints(curve, 8); // Using 8 as a default value
        ctx.fillStyle = 'red';
        interpolated.forEach(point => {
            ctx.beginPath();
            ctx.arc(point.x * width, point.y * height, 3, 0, 2 * Math.PI);
            ctx.fill();
        });
    };

    const interpolatePoints = (points, count) => {
        if (points.length < 2) return points;

        let totalLength = 0;
        const segmentLengths = [];
        for (let i = 1; i < points.length; i++) {
            const dx = points[i].x - points[i - 1].x;
            const dy = points[i].y - points[i - 1].y;
            const segmentLength = Math.sqrt(dx * dx + dy * dy);
            segmentLengths.push(segmentLength);
            totalLength += segmentLength;
        }

        const desiredDistance = totalLength / (count - 1);

        const interpolatedPoints = [points[0]];
        let currentDistance = 0;
        let segmentIndex = 0;
        let segmentPosition = 0;

        for (let i = 1; i < count - 1; i++) {
            currentDistance += desiredDistance;

            while (currentDistance > segmentLengths[segmentIndex] && segmentIndex < segmentLengths.length - 1) {
                currentDistance -= segmentLengths[segmentIndex];
                segmentIndex++;
                segmentPosition = 0;
            }

            segmentPosition = currentDistance / segmentLengths[segmentIndex];
            const p1 = points[segmentIndex];
            const p2 = points[segmentIndex + 1];

            const newPoint = {
                x: p1.x + (p2.x - p1.x) * segmentPosition,
                y: p1.y + (p2.y - p1.y) * segmentPosition
            };

            interpolatedPoints.push(newPoint);
        }

        interpolatedPoints.push(points[points.length - 1]);

        return interpolatedPoints;
    };

    const handleMouseDown = (e) => {
        if (renderMode === 'O' && !isLinked) return;
        setIsDrawing(true);
        const canvas = canvasRef.current;
        const rect = canvas.getBoundingClientRect();
        const x = (e.clientX - rect.left) / width;
        const y = (e.clientY - rect.top) / height;
        onCurveChange([{ x, y }]);
    };

    const handleMouseMove = (e) => {
        if (!isDrawing || (renderMode === 'O' && !isLinked)) return;
        const canvas = canvasRef.current;
        const rect = canvas.getBoundingClientRect();
        const x = (e.clientX - rect.left) / width;
        const y = (e.clientY - rect.top) / height;
        onCurveChange((prevCurve) => [...prevCurve, { x, y }]);
    };

    const handleMouseUp = () => {
        setIsDrawing(false);
    };

    const handleOverrideChange = (value) => {
        const newValue = Math.max(0, Math.min(value, totalRects - 1));
        setOverrideValue(newValue);
        onOverrideChange(newValue);
    };

    return (
        <div className="w-32 border border-gray-300">
            <canvas
                ref={canvasRef}
                width={width}
                height={height}
                onMouseDown={handleMouseDown}
                onMouseMove={handleMouseMove}
                onMouseUp={handleMouseUp}
                onMouseLeave={handleMouseUp}
                className="border-b border-gray-300"
            />
            {!isLinked && (
                <div>
                    <RenderModeToggle
                        renderMode={renderMode}
                        onToggle={onRenderModeToggle}
                    />
                    {renderMode === 'O' && (
                        <div className="flex items-center justify-between mt-1 px-1">
                            <button onClick={() => handleOverrideChange(overrideValue - 1)} className="px-2 py-1 bg-gray-200 rounded">-</button>
                            <input
                                type="number"
                                value={overrideValue}
                                onChange={(e) => handleOverrideChange(parseInt(e.target.value, 10))}
                                className="w-10 text-center"
                                min="0"
                                max={totalRects - 1}
                            />
                            <button onClick={() => handleOverrideChange(overrideValue + 1)} className="px-2 py-1 bg-gray-200 rounded">+</button>
                        </div>
                    )}
                </div>
            )}
            <div className="text-xs text-center mt-1">
                {isLinked ? 'Linked' : 'Unlinked'}
            </div>
            {isLinked && (
                <>
                    <ForwardAxisToggle
                        label="Forward Axis"
                        value={forwardAxis}
                        onChange={(value) => onAxisChange('forwardAxis', value)}
                    />
                    <UpAxisToggle
                        label="Up Axis"
                        value={upAxis}
                        onChange={(value) => onAxisChange('upAxis', value)}
                    />
                </>
            )}
        </div>
    );
};

export default ControlPlot;