import React, { useCallback, useState } from 'react';
import ControlPlot from './ControlPlot';
import PointsManager from './PointsManager';

const GridPlotterMainWindow = () => {
    const [gridEnabled, setGridEnabled] = useState(false);
    const [gridSize, setGridSize] = useState(16);
    const [cursorEnabled, setCursorEnabled] = useState(false);
    const [cursorScale, setCursorScale] = useState(1);
    const [lists, setLists] = useState([{ color: '#ff0000', points: [], visible: true, type: 'vertical' }]);
    const [activeListIndex, setActiveListIndex] = useState(0);

    // New state for cursor settings
    const [startingScale, setStartingScale] = useState(1);
    const [incrementValue, setIncrementValue] = useState(0.1);
    const [incrementRatePerSecond, setIncrementRatePerSecond] = useState(1);
    const [scaleBleedEnabled, setScaleBleedEnabled] = useState(true);
    const [renderOrder, setRenderOrder] = useState('front'); // New state for render order

    const toggleGrid = () => {
        setGridEnabled(!gridEnabled);
    };

    const handleGridSizeChange = (event) => {
        setGridSize(Number(event.target.value));
    };

    const toggleCursor = () => {
        setCursorEnabled(!cursorEnabled);
    };

    const handleCursorScaleChange = (newScale) => {
        setCursorScale(newScale);
    };

    const handleListAdd = useCallback(() => {
        setLists(prevLists => {
            const activeListColor = prevLists[activeListIndex].color;
            return [...prevLists, { color: activeListColor, points: [], visible: true, type: 'vertical' }];
        });
        setActiveListIndex(lists.length); // Set the new list as active
    }, [lists.length, activeListIndex]);

    const handleListRemove = useCallback(() => {
        if (lists.length > 1) {
            setLists(prevLists => prevLists.filter((_, index) => index !== activeListIndex));
            setActiveListIndex(prev => {
                if (prev === lists.length - 1) {
                    // If we're removing the last list, set the new active index to the new last list
                    return prev - 1;
                }
                // Otherwise, keep the same index (it will now point to the next list)
                return prev;
            });
        }
    }, [lists.length, activeListIndex]);

    const handleListUpdate = useCallback((index, updatedList) => {
        setLists(prevLists => prevLists.map((list, i) => i === index ? updatedList : list));
    }, []);

    const handleListSelect = useCallback((index) => {
        setActiveListIndex(index);
    }, []);

    const handlePointAdd = useCallback((point) => {
        setLists(prevLists => prevLists.map((list, index) =>
            index === activeListIndex
                ? { ...list, points: [...list.points, point] }
                : list
        ));
    }, [activeListIndex]);

    const exportData = () => {
        const dataToExport = lists.map((list, index) => ({
            label: `List ${index + 1}`,
            color: list.color,
            points: list.points.map(point => ({
                x: point.x,
                y: point.y,
                z: 0, // Add z coordinate with default value 0
                scale: point.scale || 1
            })),
            type: list.type,
            visible: list.visible
        }));

        const jsonString = JSON.stringify(dataToExport, null, 2);
        const blob = new Blob([jsonString], { type: 'application/json' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'plot_data.json';
        a.click();
        URL.revokeObjectURL(url);
    };

    const importData = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                try {
                    const importedData = JSON.parse(e.target.result);
                    setLists(importedData.map(item => ({
                        color: item.color,
                        points: item.points,
                        visible: item.visible,
                        type: item.type
                    })));
                } catch (error) {
                    console.error("Error parsing imported data:", error);
                    alert("Error importing data. Please check the file format.");
                }
            };
            reader.readAsText(file);
        }
    };

    return (
        <div className="grid-plotter-main-window">
            <div className="top-row">
                <div
                    className="workspace workspace1"
                    style={{
                        width: '512px',
                        height: '512px',
                    }}
                >
                    <ControlPlot
                        gridSize={gridSize}
                        gridEnabled={gridEnabled}
                        cursorEnabled={cursorEnabled}
                        onCursorScaleChange={handleCursorScaleChange}
                        lists={lists}
                        onPointAdd={handlePointAdd}
                        startingScale={startingScale}
                        incrementValue={incrementValue}
                        incrementRatePerSecond={incrementRatePerSecond}
                        scaleBleedEnabled={scaleBleedEnabled}
                        renderOrder={renderOrder}
                    />
                </div>
                <div
                    className="workspace workspace2"
                    style={{
                        width: '256px',
                        height: '512px',
                        backgroundColor: '#444444',
                        overflowY: 'auto'
                    }}
                >
                    <PointsManager
                        lists={lists}
                        activeListIndex={activeListIndex}
                        onListAdd={handleListAdd}
                        onListRemove={handleListRemove}
                        onListUpdate={handleListUpdate}
                        onListSelect={handleListSelect}
                    />
                </div>
            </div>
            <div className="bottom-row">
                <div
                    className="workspace workspace3"
                    style={{
                        width: '512px',
                        height: '256px',
                        backgroundColor: '#444444',
                        padding: '10px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        justifyContent: 'flex-start',
                    }}
                >
                    {/* Grid Settings */}
                    <div style={{ marginBottom: '20px' }}>
                        <div style={{ marginBottom: '10px', fontWeight: 'bold', color: 'white' }}>
                            Grid Settings
                        </div>
                        <div style={{ display: 'flex', gap: '10px' }}>
                            <button
                                onClick={toggleGrid}
                                style={{
                                    padding: '5px 10px',
                                    backgroundColor: '#555',
                                    color: 'white',
                                    border: '1px solid #777',
                                    fontWeight: 'bold',
                                    cursor: 'pointer'
                                }}
                            >
                                {gridEnabled ? 'Disable GridMode' : 'Enable GridMode'}
                            </button>
                            <select
                                value={gridSize}
                                onChange={handleGridSizeChange}
                                style={{
                                    padding: '5px',
                                    backgroundColor: '#555',
                                    color: 'white',
                                    border: '1px solid #777',
                                    fontWeight: 'bold',
                                    cursor: 'pointer'
                                }}
                            >
                                <option value={16}>16x16</option>
                                <option value={32}>32x32</option>
                                <option value={64}>64x64</option>
                                <option value={128}>128x128</option>
                                <option value={256}>256x256</option>
                                <option value={512}>512x512</option>
                            </select>
                        </div>
                    </div>

                    {/* Cursor Settings */}
                    <div style={{ marginBottom: '20px', width: '100%' }}>
                        <div style={{ marginBottom: '10px', fontWeight: 'bold', color: 'white' }}>
                            Cursor Settings
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                            <button
                                onClick={toggleCursor}
                                style={{
                                    padding: '5px 10px',
                                    backgroundColor: cursorEnabled ? '#777' : '#555',
                                    color: 'white',
                                    border: '1px solid #777',
                                    fontWeight: 'bold',
                                    cursor: 'pointer'
                                }}
                            >
                                {cursorEnabled ? 'Disable Cursor' : 'Enable Cursor'}
                            </button>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                <label style={{ color: 'white' }}>Starting Scale:</label>
                                <input
                                    type="number"
                                    value={startingScale}
                                    onChange={(e) => setStartingScale(Number(e.target.value))}
                                    style={{ width: '60px', backgroundColor: '#555', color: 'white', border: '1px solid #777' }}
                                />
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                <label style={{ color: 'white' }}>Increment Value:</label>
                                <input
                                    type="number"
                                    value={incrementValue}
                                    onChange={(e) => setIncrementValue(Number(e.target.value))}
                                    style={{ width: '60px', backgroundColor: '#555', color: 'white', border: '1px solid #777' }}
                                />
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                <label style={{ color: 'white' }}>Increment Rate (per second):</label>
                                <input
                                    type="number"
                                    value={incrementRatePerSecond}
                                    onChange={(e) => setIncrementRatePerSecond(Number(e.target.value))}
                                    style={{ width: '60px', backgroundColor: '#555', color: 'white', border: '1px solid #777' }}
                                />
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                <label style={{ color: 'white' }}>Enable Scale Bleed:</label>
                                <input
                                    type="checkbox"
                                    checked={scaleBleedEnabled}
                                    onChange={(e) => setScaleBleedEnabled(e.target.checked)}
                                    style={{ cursor: 'pointer' }}
                                />
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                <label style={{ color: 'white' }}>Render Order:</label>
                                <div style={{ display: 'flex', gap: '5px' }}>
                                    <button
                                        onClick={() => setRenderOrder('front')}
                                        style={{
                                            padding: '5px 10px',
                                            backgroundColor: renderOrder === 'front' ? '#777' : '#555',
                                            color: 'white',
                                            border: '1px solid #777',
                                            cursor: 'pointer'
                                        }}
                                    >
                                        Front
                                    </button>
                                    <button
                                        onClick={() => setRenderOrder('back')}
                                        style={{
                                            padding: '5px 10px',
                                            backgroundColor: renderOrder === 'back' ? '#777' : '#555',
                                            color: 'white',
                                            border: '1px solid #777',
                                            cursor: 'pointer'
                                        }}
                                    >
                                        Back
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div style={{ color: 'white', marginTop: '10px', fontSize: '12px' }}>
                            Hold Shift + Mouse Wheel to scale cursor
                        </div>
                    </div>

                    {/* Data Import/Export */}
                    <div style={{ marginTop: '20px', display: 'flex', gap: '10px' }}>
                        <input
                            type="file"
                            id="import-data"
                            style={{ display: 'none' }}
                            onChange={importData}
                            accept=".json"
                        />
                        <label
                            htmlFor="import-data"
                            style={{
                                padding: '5px 10px',
                                backgroundColor: '#555',
                                color: 'white',
                                border: '1px solid #777',
                                borderRadius: '3px',
                                cursor: 'pointer'
                            }}
                        >
                            Import Data
                        </label>
                        <button
                            onClick={exportData}
                            style={{
                                padding: '5px 10px',
                                backgroundColor: '#555',
                                color: 'white',
                                border: '1px solid #777',
                                borderRadius: '3px',
                                cursor: 'pointer'
                            }}
                        >
                            Export Data
                        </button>
                    </div>
                </div>
                <div style={{ width: '256px', height: '256px' }}></div>
            </div>
        </div>
    );
};

export default GridPlotterMainWindow;