import React, { useEffect } from 'react';
import '../plotter/plotter.css';
import GridPlotterMainWindow from './GridPlotter/GridPlotterMainWindow';

function Gridplotter() {
    useEffect(() => {
        const preventDefault = (e) => e.preventDefault();

        // Disable context menu
        document.addEventListener('contextmenu', preventDefault);

        // Disable text selection
        document.body.style.userSelect = 'none';
        document.body.style.webkitUserSelect = 'none';
        document.body.style.msUserSelect = 'none';
        document.body.style.mozUserSelect = 'none';

        return () => {
            // Clean up event listeners when component unmounts
            document.removeEventListener('contextmenu', preventDefault);

            // Reset text selection styles
            document.body.style.userSelect = '';
            document.body.style.webkitUserSelect = '';
            document.body.style.msUserSelect = '';
            document.body.style.mozUserSelect = '';
        };
    }, []);

    return (
        <div className="App">
            <GridPlotterMainWindow />
        </div>
    );
}

export default Gridplotter;