import React from 'react';
import '../../plotter/plotter.css';
import ControlPlotInput from './ControlPlotInput';

const ControlPlot = ({
    gridSize,
    gridEnabled,
    cursorEnabled,
    onCursorScaleChange,
    lists,
    onPointAdd,
    startingScale,
    incrementValue,
    incrementRatePerSecond,
    scaleBleedEnabled
}) => {
    return (
        <div className="control-plot">
            <ControlPlotInput
                gridSize={gridSize}
                gridEnabled={gridEnabled}
                cursorEnabled={cursorEnabled}
                onCursorScaleChange={onCursorScaleChange}
                lists={lists}
                onPointAdd={onPointAdd}
                startingScale={startingScale}
                incrementValue={incrementValue}
                incrementRatePerSecond={incrementRatePerSecond}
                scaleBleedEnabled={scaleBleedEnabled}
            >
                <div className="center-line vertical"></div>
                <div className="center-line horizontal"></div>
            </ControlPlotInput>
        </div>
    );
};

export default ControlPlot;