import { Home, MapPin } from 'lucide-react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import StyledButton from './StyledButton';

const WorldMap = () => {
    const MAP_WIDTH = 5000;
    const MAP_HEIGHT = 5000;
    const VIEW_WIDTH = 800;
    const VIEW_HEIGHT = 600;
    const OVERVIEW_SIZE = 150;
    const MIN_ZOOM = 1;
    const MAX_ZOOM = 3;

    const [mapPosition, setMapPosition] = useState({ x: 0, y: 0 });
    const [isDragging, setIsDragging] = useState(false);
    const [startPosition, setStartPosition] = useState({ x: 0, y: 0 });
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [showOverview, setShowOverview] = useState(true);
    const [zoom, setZoom] = useState(1);
    const mapRef = useRef(null);
    const containerRef = useRef(null);
    const [selectedLocationName, setSelectedLocationName] = useState(null);
    const [flashingLocations, setFlashingLocations] = useState([]);
    const [isFlashing, setIsFlashing] = useState(false);
    const FLASH_RADIUS = 4;
    const [isOverviewDragging, setIsOverviewDragging] = useState(false);

    const calculatePreviewRect = () => {
        const visibleWidth = VIEW_WIDTH / zoom;
        const visibleHeight = VIEW_HEIGHT / zoom;

        const left = (-mapPosition.x / zoom / MAP_WIDTH) * OVERVIEW_SIZE;
        const top = (-mapPosition.y / zoom / MAP_HEIGHT) * OVERVIEW_SIZE;
        const width = (visibleWidth / MAP_WIDTH) * OVERVIEW_SIZE;
        const height = (visibleHeight / MAP_HEIGHT) * OVERVIEW_SIZE;

        return {
            left: Math.max(0, Math.min(OVERVIEW_SIZE - width, left)),
            top: Math.max(0, Math.min(OVERVIEW_SIZE - height, top)),
            width: Math.min(OVERVIEW_SIZE, width),
            height: Math.min(OVERVIEW_SIZE, height),
        };
    };

    const handleOverviewMouseDown = (e) => {
        e.preventDefault();
        setIsOverviewDragging(true);
        updateMapPositionFromOverview(e);
    };

    const handleOverviewMouseMove = (e) => {
        if (isOverviewDragging) {
            updateMapPositionFromOverview(e);
        }
    };

    const handleOverviewMouseUp = () => {
        setIsOverviewDragging(false);
    };

    const updateMapPositionFromOverview = (e) => {
        const rect = e.currentTarget.getBoundingClientRect();
        const x = (e.clientX - rect.left) * (MAP_WIDTH / OVERVIEW_SIZE);
        const y = (e.clientY - rect.top) * (MAP_HEIGHT / OVERVIEW_SIZE);

        setMapPosition({
            x: Math.min(0, Math.max(VIEW_WIDTH / 2 - x, VIEW_WIDTH - MAP_WIDTH * zoom)),
            y: Math.min(0, Math.max(VIEW_HEIGHT / 2 - y, VIEW_HEIGHT - MAP_HEIGHT * zoom)),
        });
    };

    const handleWheel = useCallback((e) => {
        e.preventDefault();
        const rect = containerRef.current.getBoundingClientRect();
        const mouseX = e.clientX - rect.left;
        const mouseY = e.clientY - rect.top;

        const delta = e.deltaY;
        setZoom((prevZoom) => {
            const newZoom = prevZoom * (delta > 0 ? 0.9 : 1.1);
            const constrainedZoom = Math.max(MIN_ZOOM, Math.min(MAX_ZOOM, newZoom));

            const zoomFactor = constrainedZoom / prevZoom;

            setMapPosition((prevPosition) => {
                const newX = mouseX - (mouseX - prevPosition.x) * zoomFactor;
                const newY = mouseY - (mouseY - prevPosition.y) * zoomFactor;

                return {
                    x: Math.min(0, Math.max(newX, VIEW_WIDTH - MAP_WIDTH * constrainedZoom)),
                    y: Math.min(0, Math.max(newY, VIEW_HEIGHT - MAP_HEIGHT * constrainedZoom)),
                };
            });

            return constrainedZoom;
        });
    }, []);

    useEffect(() => {
        const mapContainer = containerRef.current;
        if (mapContainer) {
            mapContainer.addEventListener('wheel', handleWheel, { passive: false });
        }
        return () => {
            if (mapContainer) {
                mapContainer.removeEventListener('wheel', handleWheel);
            }
        };
    }, [handleWheel]);




    const locationColors = [
        'text-red-400', 'text-blue-400', 'text-green-400', 'text-yellow-400', 'text-purple-400',
        'text-pink-400', 'text-indigo-400', 'text-orange-400', 'text-teal-400', 'text-cyan-400'
    ];

    const baseLandmarks = [
        { name: 'Ancient Forest', color: locationColors[0] },
        { name: 'Mystic Mountain', color: locationColors[1] },
        { name: 'Enchanted Lake', color: locationColors[2] },
        { name: 'Dragon\'s Lair', color: locationColors[3] },
        { name: 'Haunted Castle', color: locationColors[4] },
        { name: 'Crystal Caves', color: locationColors[5] },
        { name: 'Sunken Temple', color: locationColors[6] },
        { name: 'Sky Citadel', color: locationColors[7] },
        { name: 'Whispering Woods', color: locationColors[8] },
        { name: 'Eternal Battlefield', color: locationColors[9] },
    ];

    const baseShops = [
        { name: 'Blacksmith', color: 'text-gray-400' },
        { name: 'Potion Shop', color: 'text-pink-300' },
        { name: 'Magic Scrolls', color: 'text-purple-300' },
        { name: 'Adventurer\'s Gear', color: 'text-yellow-300' },
        { name: 'Mystic Jeweler', color: 'text-blue-300' },
        { name: 'Enchanted Armory', color: 'text-green-300' },
        { name: 'Alchemist\'s Lab', color: 'text-red-300' },
        { name: 'Beastmaster\'s Menagerie', color: 'text-orange-300' },
        { name: 'Celestial Observatory', color: 'text-indigo-300' },
        { name: 'Dwarven Forge', color: 'text-amber-300' },
    ];

    const generateRandomLocations = useCallback((baseLocations, count, prefix) => {
        return Array.from({ length: count }, (_, i) => ({
            id: `${prefix}${i + 1}`,
            ...baseLocations[i % baseLocations.length],
            x: Math.floor(Math.random() * (MAP_WIDTH - 100) + 50),
            y: Math.floor(Math.random() * (MAP_HEIGHT - 100) + 50),
        }));
    }, [MAP_WIDTH, MAP_HEIGHT]);

    const [landmarks, setLandmarks] = useState(() => generateRandomLocations(baseLandmarks, 50, 'L'));
    const [shops, setShops] = useState(() => generateRandomLocations(baseShops, 30, 'S'));

    const allLocations = [...landmarks, ...shops];

    const handleMouseDown = (e) => {
        setIsDragging(true);
        setStartPosition({ x: e.clientX - mapPosition.x, y: e.clientY - mapPosition.y });
    };

    const handleMouseMove = (e) => {
        if (isDragging) {
            const newX = e.clientX - startPosition.x;
            const newY = e.clientY - startPosition.y;

            setMapPosition({
                x: Math.min(0, Math.max(newX, VIEW_WIDTH - MAP_WIDTH)),
                y: Math.min(0, Math.max(newY, VIEW_HEIGHT - MAP_HEIGHT)),
            });
        }
    };

    const handleMouseUp = () => {
        setIsDragging(false);
    };

    const createSafeClassName = (name) => {
        return name
            .toLowerCase()
            .replace(/[^a-z0-9]+/g, '_') // Replace any non-alphanumeric character with underscore
            .replace(/^_+|_+$/g, '')     // Remove leading and trailing underscores
            .replace(/_+/g, '_');        // Replace multiple underscores with a single one
    };

    const handleLocationClick = (location) => {
        setSelectedLocation(location);
        setSelectedLocationName(location.name);
        setFlashingLocations(allLocations.filter(loc => loc.name === location.name));
        setIsFlashing(true);
    };

    useEffect(() => {
        if (flashingLocations.length > 0) {
            const flashInterval = setInterval(() => {
                setIsFlashing(prev => !prev);
            }, 300);

            setTimeout(() => {
                clearInterval(flashInterval);
                setFlashingLocations([]);
                setIsFlashing(false);
            }, 1800);

            return () => clearInterval(flashInterval);
        }
    }, [flashingLocations]);

    const handleSearch = (e) => {
        e.preventDefault();
        const location = allLocations.find(loc => loc.name.toLowerCase() === searchTerm.toLowerCase());
        if (location) {
            setSelectedLocation(location);
            setMapPosition({
                x: Math.min(0, Math.max(VIEW_WIDTH / 2 - location.x, VIEW_WIDTH - MAP_WIDTH)),
                y: Math.min(0, Math.max(VIEW_HEIGHT / 2 - location.y, VIEW_HEIGHT - MAP_HEIGHT)),
            });
        }
    };

    const handleOverviewClick = (e) => {
        const rect = e.target.getBoundingClientRect();
        const x = (e.clientX - rect.left) * (MAP_WIDTH / OVERVIEW_SIZE);
        const y = (e.clientY - rect.top) * (MAP_HEIGHT / OVERVIEW_SIZE);

        setMapPosition({
            x: Math.min(0, Math.max(VIEW_WIDTH / 2 - x, VIEW_WIDTH - MAP_WIDTH)),
            y: Math.min(0, Math.max(VIEW_HEIGHT / 2 - y, VIEW_HEIGHT - MAP_HEIGHT)),
        });
    };

    useEffect(() => {
        if (selectedLocationName) {
            const safeClassName = createSafeClassName(selectedLocationName);
            const pinsToHighlight = mapRef.current.querySelectorAll(`.location-${safeClassName}`);

            const timer = setInterval(() => {
                pinsToHighlight.forEach(pin => pin.classList.toggle('highlight'));
            }, 200);

            setTimeout(() => {
                clearInterval(timer);
                pinsToHighlight.forEach(pin => pin.classList.remove('highlight'));
                setSelectedLocation(null);
                setSelectedLocationName(null);
            }, 1200);

            return () => clearInterval(timer);
        }
    }, [selectedLocationName]);

    const LocationList = ({ title, locations }) => {
        const uniqueLocations = Array.from(new Set(locations.map(loc => loc.name))).map(name =>
            locations.find(loc => loc.name === name)
        );

        return (
            <div>
                <h2 className="p-2 bg-neutral-700 text-center">{title}</h2>
                <ul className="h-[calc(50vh-2rem)] overflow-y-auto">
                    {uniqueLocations.map((location) => (
                        <li
                            key={location.name}
                            className="h-6 p-1 border-b border-neutral-700 cursor-pointer hover:bg-neutral-700 text-xs flex items-center"
                            onClick={() => handleLocationClick(location)}
                        >
                            {location.id.startsWith('L') ? (
                                <MapPin size={12} className={`mr-1 ${location.color}`} />
                            ) : (
                                <Home size={12} className={`mr-1 ${location.color}`} />
                            )}
                            <span className="truncate">{location.name}</span>
                        </li>
                    ))}
                </ul>
            </div>
        );
    };

    return (
        <div className="flex flex-col h-screen bg-neutral-800 text-white font-mono">
            <div className="flex flex-1 overflow-hidden">
                <div className="w-[200px] border-r border-neutral-700 overflow-y-auto">
                    <LocationList title="Landmarks" locations={landmarks} />
                    <LocationList title="Shops" locations={shops} />
                </div>
                <div className="flex-1 relative overflow-hidden" ref={containerRef}>
                    <div
                        ref={mapRef}
                        className="absolute bg-neutral-900 cursor-move"
                        style={{
                            width: `${MAP_WIDTH * zoom}px`,
                            height: `${MAP_HEIGHT * zoom}px`,
                            transform: `translate(${mapPosition.x}px, ${mapPosition.y}px)`,
                        }}
                        onMouseDown={handleMouseDown}
                        onMouseMove={handleMouseMove}
                        onMouseUp={handleMouseUp}
                        onMouseLeave={handleMouseUp}
                    >
                        {allLocations.map((location) => (
                            <div
                                key={location.id}
                                id={`pin-${location.id}`}
                                className={`absolute location-${createSafeClassName(location.name)}`}
                                style={{
                                    left: location.x * zoom,
                                    top: location.y * zoom,
                                    transform: `scale(${1 / zoom})`,
                                    transformOrigin: 'top left'
                                }}
                                onClick={() => handleLocationClick(location)}
                            >
                                {location.id.startsWith('L') ? (
                                    <MapPin className={location.color} size={24} />
                                ) : (
                                    <Home className={location.color} size={24} />
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className="h-12 bg-neutral-700 flex items-center justify-between px-4">
                <form onSubmit={handleSearch} className="flex">
                    <input
                        type="text"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        placeholder="Search locations..."
                        className="bg-neutral-600 text-white px-2 py-1 rounded"
                    />
                    <StyledButton type="submit" variant="primary">
                        Search
                    </StyledButton>
                </form>
                <StyledButton
                    onClick={() => setShowOverview(!showOverview)}
                    variant="success"
                >
                    {showOverview ? 'Hide Overview' : 'Show Overview'}
                </StyledButton>
            </div>
            {showOverview && (
                <div
                    className="absolute bottom-16 right-4 bg-neutral-800 border border-neutral-600 cursor-move"
                    style={{ width: `${OVERVIEW_SIZE}px`, height: `${OVERVIEW_SIZE}px` }}
                    onMouseDown={handleOverviewMouseDown}
                    onMouseMove={handleOverviewMouseMove}
                    onMouseUp={handleOverviewMouseUp}
                    onMouseLeave={handleOverviewMouseUp}
                >
                    {allLocations.map((location) => (
                        <div
                            key={location.id}
                            className="absolute w-1 h-1 bg-white"
                            style={{
                                left: (location.x / MAP_WIDTH) * OVERVIEW_SIZE,
                                top: (location.y / MAP_HEIGHT) * OVERVIEW_SIZE,
                            }}
                        />
                    ))}
                    {isFlashing && flashingLocations.map((location) => (
                        <div
                            key={`flash-${location.id}`}
                            className="absolute bg-yellow-400 rounded-full"
                            style={{
                                left: (location.x / MAP_WIDTH) * OVERVIEW_SIZE - FLASH_RADIUS,
                                top: (location.y / MAP_HEIGHT) * OVERVIEW_SIZE - FLASH_RADIUS,
                                width: `${FLASH_RADIUS * 2}px`,
                                height: `${FLASH_RADIUS * 2}px`,
                            }}
                        />
                    ))}
                    <div
                        className="absolute border-2 border-red-500"
                        style={calculatePreviewRect()}
                    />
                </div>
            )}
            <style jsx>{`
                .highlight {
                    filter: drop-shadow(0 0 5px yellow);
                }
            `}</style>
        </div>
    );
};

export default WorldMap;