import React from 'react';

const PointsManager = ({ lists, activeListIndex, onListAdd, onListRemove, onListUpdate, onListSelect }) => {
    const toggleVisibility = (index) => {
        const updatedList = { ...lists[index], visible: !lists[index].visible };
        onListUpdate(index, updatedList);
    };

    const renderOptions = [
        { label: 'Circle', value: 'circle' },
        { label: 'Triangle', value: 'triangle' },
        { label: 'Rect', value: 'rect' },
        { label: 'Horizontal', value: 'horizontal' },
        { label: 'Vertical', value: 'vertical' },
        { label: '45 DiagLeft', value: 'diagLeft' },
        { label: '45 DiagRight', value: 'diagRight' },
    ];

    return (
        <div className="points-manager" style={{ padding: '10px', display: 'flex', flexDirection: 'column' }}>
            {lists.map((list, index) => (
                <div
                    key={index}
                    onClick={() => onListSelect(index)}
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        marginBottom: '10px',
                        padding: '5px',
                        backgroundColor: index === activeListIndex ? '#777' : '#555',
                        border: '1px solid #777',
                        cursor: 'pointer'
                    }}
                >
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
                        <button
                            onClick={(e) => {
                                e.stopPropagation();
                                toggleVisibility(index);
                            }}
                            style={{
                                width: '20px',
                                height: '20px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                marginRight: '10px',
                                backgroundColor: '#444',
                                border: '1px solid #666',
                                borderRadius: '50%',
                                color: 'white',
                                fontSize: '16px',
                                cursor: 'pointer'
                            }}
                        >
                            {list.visible ? '•' : '-'}
                        </button>
                        <span style={{ color: 'white', fontWeight: 'bold', marginRight: '10px' }}>
                            List {index + 1}
                        </span>
                        <div style={{ display: 'flex', marginRight: '10px' }}>
                            <button
                                onClick={(e) => {
                                    e.stopPropagation();
                                    onListUpdate(index, { ...list, type: 'vertical' });
                                }}
                                style={{
                                    padding: '2px 5px',
                                    backgroundColor: list.type === 'vertical' ? '#999' : '#555',
                                    color: 'white',
                                    border: '1px solid #777',
                                    cursor: 'pointer',
                                    marginRight: '2px'
                                }}
                            >
                                V
                            </button>
                            <button
                                onClick={(e) => {
                                    e.stopPropagation();
                                    onListUpdate(index, { ...list, type: 'horizontal' });
                                }}
                                style={{
                                    padding: '2px 5px',
                                    backgroundColor: list.type === 'horizontal' ? '#999' : '#555',
                                    color: 'white',
                                    border: '1px solid #777',
                                    cursor: 'pointer'
                                }}
                            >
                                H
                            </button>
                        </div>
                        <input
                            type="color"
                            value={list.color}
                            onChange={(e) => {
                                e.stopPropagation();
                                onListUpdate(index, { ...list, color: e.target.value });
                            }}
                            style={{ marginLeft: 'auto' }}
                            onClick={(e) => e.stopPropagation()}
                        />
                    </div>
                    <div style={{ display: 'flex', flexWrap: 'wrap', gap: '5px' }}>
                        {renderOptions.map((option) => (
                            <button
                                key={option.value}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    onListUpdate(index, { ...list, renderType: option.value });
                                }}
                                style={{
                                    padding: '2px 5px',
                                    backgroundColor: list.renderType === option.value ? '#999' : '#555',
                                    color: 'white',
                                    border: '1px solid #777',
                                    cursor: 'pointer',
                                    fontSize: '12px'
                                }}
                            >
                                {option.label}
                            </button>
                        ))}
                    </div>
                </div>
            ))}
            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
                <button
                    onClick={onListAdd}
                    style={{
                        padding: '5px 10px',
                        backgroundColor: '#555',
                        color: 'white',
                        border: '1px solid #777',
                        cursor: 'pointer'
                    }}
                >
                    Add List
                </button>
                <button
                    onClick={onListRemove}
                    style={{
                        padding: '5px 10px',
                        backgroundColor: '#555',
                        color: 'white',
                        border: '1px solid #777',
                        cursor: 'pointer'
                    }}
                >
                    Remove List
                </button>
            </div>
        </div>
    );
};

export default PointsManager;